<template>
  <div class="page-wrap">
    <div class="session-form-hold">
      <base-card>
        <v-progress-linear
          :active="loading"
          :indeterminate="loading"
          absolute
          top
          color="primary"
        />
        <v-alert
          v-model="hasError"
          type="error"
        >
          {{ errorMessage }}
        </v-alert>
        <v-card-text class="text-center">
          <h6 class="text--disabled font-weight-medium mb-10">
            Sign in to your account
          </h6>
          <v-form>
            <v-text-field
              v-model="email"
              label="Email"
              :rules="emailRules"
              required
            />

            <v-text-field
              v-model="password"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              name="input-10-2"
              label="Password"
              :rules="nameRules"
              @click:append="show = !show"
            />
            <v-checkbox
              v-model="checkbox1"
              label="Remember this computer"
            />
            <v-btn
              class="mb-4"
              block
              color="primary"
              dark
              @click="formSubmit"
            >
              <v-icon left>
                mdi-login
              </v-icon>
              Sign In
            </v-btn>
            <div class="d-flex justify-around flex-wrap">
              <v-btn
                text
                small
                color="primary"
                class="mb-2"
              >
                Forgot Password
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </base-card>
    </div>
  </div>
</template>
<script>
  import authentication from '@/services/authentication.js'
  import { resolve } from 'path'
  export default {
    name: 'Login',
    metaInfo: {
      // title will be injected into parent titleTemplate
      title: 'Login',
    },
    data () {
      return {
        show: false,
        password: '',
        checkbox1: false,
        email: '',
        hasError: false,
        errorMessage: '',
        loading: false,
        emailRules: [
          (v) => !!v || 'E-mail is required',
          (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
        nameRules: [
          (v) => !!v || 'Password is required',
          (v) =>
            (v && v.length <= 10) ||
            'Name must be less than 10 characters',
        ],
      }
    },
    computed: {

    },
    methods: {
      formSubmit () {
        authentication.login(this.email, this.password).then(response => {
          localStorage.setItem('token', response.data.access_token)
          localStorage.setItem('userInfo', JSON.stringify(response.data.utilisateur))
          this.$router.push('/')
        }).catch(error => {
          console.log(error.response.data.success.message)
          this.errorMessage = error.response.data.success.message
          this.hasError = true
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
.page-wrap {
    background-color: #242939 !important;
    display: flex;
    align-items: center;
    padding: 40px 1rem;
    height: 100%;
    min-height: 100vh;
}

.session-form-hold {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
}
</style>
