import axios from 'axios'
const apiClient = axios.create({
  baseURL: 'https://web-api-sgp.piebarometre.digital/',
  withCredentials: false,
  headers: {
     Accept: 'application/json',
     'Content-Type': 'application/json',
  },
})

export default {

  login (email, password) {
        return apiClient.post('api/auth-request/connexion', {
          email: email,
          password: password,
        })
  },
}
